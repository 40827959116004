/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs";
import {} from "jquery-ujs";

import("@fortawesome/fontawesome-free/css/all");
import("@fortawesome/fontawesome-free/css/v4-shims");
import("animate.css/animate.min");
import("jquery-datetimepicker/jquery.datetimepicker.css");
import("alertifyjs/build/css/alertify.min.css");
import("alertifyjs/build/css/themes/bootstrap.min.css");
import("toastr/build/toastr.min.css");

Rails.start();

import { PeVideoPlayer } from "@pe/web-components/dist/components/pe-video-player";
import { PeButton } from "@pe/web-components/dist/components/pe-button";
import { PeIcon } from "@pe/web-components/dist/components/pe-icon";
import { PeVideo } from "@pe/web-components/dist/components/pe-video";
import { PeVideoControls } from "@pe/web-components/dist/components/pe-video-controls";

global.React = require("react");
global.ReactDOM = require("react-dom");
global.$ = require("jquery");
global.jQuery = require("jquery");
require("jquery-datetimepicker");
require("jquery-ui/ui/widgets/autocomplete");
global.moment = require("moment");
global.Swal = require("sweetalert2");
global.Fluxxor = require("fluxxor");
global.classNames = require("classnames");
global._ = require("underscore");
global.alertify = require("alertifyjs");
global.toastr = require("toastr");
global.adapter = require("webrtc-adapter");
global.validator = require("validator");
global.EventEmitter = require("eventemitter3");
global.Reactable = require("reactable");
global.jsPDF = require("jspdf");
global.autoTable = require("jspdf-autotable");
global.kurentoUtils = require("kurento-utils");
global.buffer = require("buffer");

// I18n related stuff
import { I18n } from "i18n-js";
import translations from "/public/javascripts/translations.json";
global.I18n = new I18n(translations);
global.I18n.defaultLocale = "en";
global.I18n.locale = "en";
global.I18n.enableFallback = true;

// Configuration
global.PESwal = Swal.mixin({
  confirmButtonColor: "rgb(140, 212, 245)",
});
